import './manage-businesses-quickly-invoice.css';
import React from 'react';
import * as XLSX from 'xlsx';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { environment } from 'src/common/config';
import { ManageBusinessDialog } from './dialogs/manage-business/manage-business.dialog';
import { useBusinesses } from 'src/common/hooks/use-businesses.hook';
import { BusinessSubscriptionStatus, BusinessSubscriptionType } from 'src/common/enums';
import moment from 'moment';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    // Add custom styles here
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    // Add custom styles here
}));

const formatAddress = address => {
    return address ? address.city : '';
};

const QuicklyInvoiceBusinessTable = () => {
    // State for businesses data, search, and pagination
    const { businesses, fetchData } = useBusinesses();

    const hishbonMaheerBusinesses = useMemo(() => {
        return businesses.filter(e => e.originalZNPlatform === 'HISHBON_MAHEER');
    }, [businesses]);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isManageBusinessDialog, setIsManageBusinessDialog] = useState<boolean>(false);

    const [currentBusiness, setCurrentBusiness] = useState(null);

    const [businessName, setBusinessName] = useState();

    // State for adding new business
    const [openAddDialog, setOpenAddDialog] = useState(false);

    // Function to handle close of add dialog
    const handleAddBusinessDialogClose = () => {
        setOpenAddDialog(false);
    };

    const onDeleteBusiness = chosenBusiness => async () => {
        const confirmation = window.confirm(`האם אתה בטוח שברצונך למחוק את העסק? - ${chosenBusiness?.name}`);
        if (!confirmation) {
            return;
        }
        try {
            await axios.delete(`${environment.service_panel}/business/${chosenBusiness._id}`);
            alert('העסק נמחק בהצלחה');
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const getSubscriptionStatusTextForBusiness = business => {
        return business?.businessSubscription?.status === BusinessSubscriptionStatus.ACTIVE
            ? `${business?.businessSubscription?.type === BusinessSubscriptionType.ANNUAL ? 'שנתי' : 'חודשי'} ${
                  business.businessSubscription?.businessPlanAtTheTime?.name === 'Free' ? 'חינם' : 'PREMIUM'
              }`
            : business.businessSubscription?.status === BusinessSubscriptionStatus.NEED_TO_ADD_CREDIT_CARD
            ? 'NULL'
            : '';
    };

    // Filter and sort businesses based on search query
    const filteredBusinesses = useMemo(() => {
        return hishbonMaheerBusinesses
            .filter(
                business =>
                    (business.name && business.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.zenatName && business.zenatName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.businessId && business.businessId.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business?.contact?.phoneNumber &&
                        business?.contact?.phoneNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.address &&
                        business.address.city &&
                        business.address.city.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.type && business.type.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    getSubscriptionStatusTextForBusiness(business).toLowerCase().indexOf(search.toLowerCase()) !== -1,
            )
            .sort((a, b) => a.name?.localeCompare(b.name));
    }, [hishbonMaheerBusinesses, search]);

    const openManageBusinessDialog = business => (): void => {
        setCurrentBusiness(business);
        setIsManageBusinessDialog(true);
    };

    const onCloseManageBusinessDialog = (): void => {
        setCurrentBusiness(null);
        setIsManageBusinessDialog(false);
    };

    const sendWelcomeingMessage = business => (): void => {
        const text = encodeURIComponent('يعطيك العافية, بتحكي معك حلا من تطبيق الخشبونيوت חשבון מהיר.  ');
        const text2 = encodeURIComponent(
            ' شفت انك فتحت حساب بالتطبيق وبعد ما اخترت الاشتراك الي بناسبك, حبيت احكيلك انه في عنا اشتراك مجاني اذا بعدك متحير جرب مش رح تخسر وما في التزام , اذا بتحتاج اي مساعدة فيك دايما تتوجهلي هون عالواتساب ',
        );
        const message = text + '%0A' + text2;
        window.open(`https://wa.me/+972${business?.contact?.phoneNumber}?text=${message}`, '_blank');
    };

    const navigatetoRashut = () => {
        window.open(`https://taxinfo.taxes.gov.il/emosek/wHzanatTik.aspx`, '_blank');
    };

    const exportToExcel = () => {
        // Function to normalize phone numbers
        const normalizePhoneNumber = phone => {
            if (!phone) return ''; // Handle empty phone numbers
            if (phone.startsWith('0')) {
                return '972' + phone.substring(1); // Convert "050..." to "97250..."
            } else if (phone.startsWith('+972')) {
                return phone.substring(1); // Remove "+" from "+972..."
            }
            return phone; // Return phone as is if no changes needed
        };

        // Prepare the headers
        const headers = ['שם עסק חשבונאי', 'שם עסק מסחרי', 'כתובת', 'סוג', 'מנוי', 'תאריך הרשמה', 'ח.פ.', 'טלפון'];

        // Prepare the rows
        const rows = filteredBusinesses.map(business => [
            business.name,
            business.zenatName,
            formatAddress(business.address),
            business.businessType === 'INDIVIDUAL_FREE_TAX'
                ? 'עוסק פטור'
                : business.businessType === 'INDIVIDUAL'
                ? 'עוסק מורשה'
                : business.businessType === 'COMPANY'
                ? 'חברה בע"מ'
                : business.businessType,
            getSubscriptionStatusTextForBusiness(business),
            moment(business.businessSubscription?.createdAt).format('DD/MM/YYYY HH:mm'),
            business.businessId,
            normalizePhoneNumber(business?.contact?.phoneNumber), // Normalize the phone number
        ]);

        // Combine headers and rows
        const data = [headers, ...rows];

        // Create a worksheet and a workbook
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Businesses');

        // Export to file
        XLSX.writeFile(workbook, 'businesses.xlsx');
    };

    return (
        <Paper>
            <h1>ניהול עסקים חשבון מהיר</h1>
            <div className="table-header">
                <CustomTextField
                    style={{ width: '400px' }}
                    label="מנוי, טלפון, ח.פ., שם עסק, כתובת"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            <Button style={{ backgroundColor: '#275C7E' }} onClick={navigatetoRashut}>
                {' '}
                ( ! ) פרטי עוסק מורשה{' '}
            </Button>
            <Button variant="contained" color="primary" onClick={exportToExcel}>
                Export to Excel
            </Button>{' '}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>שם עסק חשבונאי</CustomTableCell>
                            <CustomTableCell>שם עסק מסחרי</CustomTableCell>
                            <CustomTableCell>כתובת</CustomTableCell>
                            <CustomTableCell>סוג</CustomTableCell>
                            <CustomTableCell>מנוי</CustomTableCell>
                            <CustomTableCell>תאריך הרשמה</CustomTableCell>
                            <CustomTableCell>ח.פ.</CustomTableCell>
                            <CustomTableCell>טלפון</CustomTableCell>
                            <CustomTableCell>Hi 2 Null</CustomTableCell>
                            {/* <CustomTableCell>עריכה</CustomTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredBusinesses
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(business => (
                                <TableRow key={business._id}>
                                    <CustomTableCell>{business.name}</CustomTableCell>
                                    <CustomTableCell>{business.zenatName}</CustomTableCell>
                                    <CustomTableCell>{formatAddress(business.address)}</CustomTableCell>
                                    <CustomTableCell>
                                        {business.businessType === 'INDIVIDUAL_FREE_TAX'
                                            ? 'עוסק פטור'
                                            : business.businessType === 'INDIVIDUAL'
                                            ? 'עוסק מורשה'
                                            : business.businessType === 'COMPANY'
                                            ? 'חברה בע"מ'
                                            : business.businessType}
                                    </CustomTableCell>
                                    <CustomTableCell>{getSubscriptionStatusTextForBusiness(business)}</CustomTableCell>
                                    <CustomTableCell>
                                        {moment(business.businessSubscription?.createdAt).format('DD/MM/YYYY HH:mm')}
                                    </CustomTableCell>
                                    <CustomTableCell>{business.businessId}</CustomTableCell>
                                    <CustomTableCell>{business?.contact?.phoneNumber}</CustomTableCell>
                                    <TableCell>
                                        <IconButton
                                            style={{ backgroundColor: '#25D366' }}
                                            onClick={sendWelcomeingMessage(business)}>
                                            <WhatsAppIcon />
                                        </IconButton>
                                    </TableCell>
                                    {/* <TableCell>
                                        <IconButton onClick={onDeleteBusiness(business)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredBusinesses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        dir="rtl"
                    />
                </Grid>
            </Grid>
            <Dialog open={openAddDialog} onClose={handleAddBusinessDialogClose} fullWidth maxWidth="md">
                <DialogContent>
                    {/* Your form fields for adding a new business go here */}
                    <TextField label="Business Name" onChange={(e: any) => setBusinessName(e.target.value)} />
                    {/* Add more form fields as needed */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddBusinessDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddBusinessDialogClose} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {isManageBusinessDialog ? 'true' : ''}
            {isManageBusinessDialog && (
                <ManageBusinessDialog currentBusiness={currentBusiness} onClose={onCloseManageBusinessDialog} />
            )}
        </Paper>
    );
};

export default QuicklyInvoiceBusinessTable;
